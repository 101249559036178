import jQuery from 'jquery'

jQuery(function () {
  jQuery('body').on('click', 'a.remote-action', function () {
    const form = jQuery(this).attr('data-form')
    jQuery(form).attr('action', jQuery(this).attr('data-url'))
    jQuery(form + ' input#remote_action').val(jQuery(this).attr('data-remote-action'))
    jQuery(form).trigger('submit')
  })
  jQuery('body').on('change', '.submit_on_change', function () {
    jQuery(this).parents('form').submit()
  })

  // override nested form to allow prepending new items instead of the default appending
  window.nestedFormEvents.insertFields = function (content, assoc, link) {
    const target = jQuery(link).data('target')
    const position = jQuery(link).data('position')
    if (target) {
      if (position === 'prepend') {
        return jQuery(content).prependTo(jQuery(target)).hide().slideDown()
      } else {
        return jQuery(content).appendTo(jQuery(target)).hide().slideDown()
      }
    } else {
      return jQuery(content).insertBefore(link)
    }
  }
})
