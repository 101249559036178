// Taken from https://github.com/arvindvyas/Country-State-Select

export function CountryStateSelect(options) {

  var state_id = options['state_id'];
  var country_id = options['country_id'];
  var city_id = options['city_id'];

  var state_name = $('#' + state_id).attr('name');
  var state_class = $('#' + state_id).attr('class');

  var city_name = $('#' + city_id).attr('name');
  var city_class = $('#' + city_id).attr('class');

  return statesDropdown();

  // ====== ***** METHODS ***** ===================================================================== //
  function statesDropdown() {

    addChosenToCountry();
    addChosenToState();
    addChosenToCity();

    $("#" + country_id).change(function () {
      return findStates($(this).val());
    });

  }

  function citiesDropdown() {
     $("#" + state_id).change(function () {
      return findCities($("#" + state_id).val(),$("#" + country_id).val());
    });
  }

  function addChosenToState(){
    if (chosenIsRequired() && stateIsNotText()) {
      $('#' + state_id).chosen(options['chosen_options']);
    }
  }

  function addChosenToCity(){
    if (chosenIsRequired() && cityIsNotText()) {
      $('#' + city_id).chosen(options['chosen_options']);
    }
  }

  function stateIsNotText(){
    return !$('#' + state_id).is("[type=text]");
  }

  function cityIsNotText(){
    return !$('#' + city_id).is("[type=text]");
  }

  function addChosenToCountry(){
    if (chosenIsRequired()) {
      $('#' + country_id).chosen(options['chosen_options']);
    }
  }

  function removeChosenFromFields(){
    if (chosenIsRequired()) {
      $("#" + options['state_id'] + "_chosen").remove();
      $("#" + options['city_id'] + "_chosen").remove();
    }
  }

  function removeChosenFromCityFields(){
    if (chosenIsRequired()) {
      $("#" + options['city_id'] + "_chosen").remove();
    }
  }

  function chosenIsRequired(){
    return options.hasOwnProperty("chosen_ui") && options['chosen_ui'];
  }

  function findStates(id) {

    //Remove all Chosen from existing fields
    removeChosenFromFields();
    findCities('','')

    //Perform AJAX request to get the data; on success, build the dropdown
    $.ajax({
      url: "/find_states",
      type: 'post',
      dataType: 'json',
      cache: false,
      data: {country_id: id},
      success: function (data) { buildStatesDropdown(data) }
    });
  }

  function findCities(state_id, country_id) {

    //Remove all Chosen from existing fields
    removeChosenFromCityFields();

    //Perform AJAX request to get the data; on success, build the dropdown
    $.ajax({
      url: "/find_cities",
      type: 'post',

      dataType: 'json',
      cache: false,
      data: {
        country_id: country_id,
        state_id: state_id
      },
      success: function (data) { buildCitiesDropdown(data) }
    });
  }

  //Build the HTML for our dropdown menus
  function buildStatesDropdown(data) {
    var html

    if (data.length === 0) {
      html = '<input id="' + state_id + '" name="' + state_name + '" class="' + state_class + '" type="text"  type="text" value="" >';
    } else {
      html = '<select id="' + state_id + '" name="' + state_name + '" class="' + state_class + '" >';
      html += '<option>'+ find_select_option_text('state') +'</option>'

      for (var i = 0; i < data.length; i++) {
        html += '<option value='+data[i][0]+'>' + data[i][1] + '</option>';
      }

      html += '</select>';
    }


    $('#' + state_id).replaceWith(html);

    //This has to happen AFTER we've replaced the dropdown or text
    if (data.length > 0) {
      addChosenToState();
    }

    // [142] FIXME # Is there any other way to call city method , it is adding change method in every state change
    if(typeof city_name !== "undefined" ){
      citiesDropdown();
    };

  }

  function find_select_option_text(type){
     if (type === 'city'){
       return options['city_place_holder'];
     }else{
       return options['state_place_holder']
     }

  }

  function buildCitiesDropdown(data) {
    var html

    if (data.length === 0) {
      html = '<input id="' + city_id + '" name="' + city_name + '" class="' + city_class + '" type="text"  type="text" value="" >';
    } else {
      html = '<select id="' + city_id + '" name="' + city_name + '" class="' + city_class + '" >';
      html += '<option>'+ find_select_option_text('city') +'</option>'
      for (i = 0; i < data.length; i++) {
        html += '<option>' + data[i] + '</option>';
      }

      html += '</select>';
    }

    $('#' + city_id).replaceWith(html);

    //This has to happen AFTER we've replaced the dropdown or text
    if (data.length > 0) {
      addChosenToCity();
    }

  }

}
