import jQuery from 'jquery'

import { CountryStateSelect } from './country_state_select'

jQuery(function () {
  const form = jQuery('form[data-address-form="true"]')
  if (form.length === 1) {
    // This page has an address form on it, so wire up
    // any country/state selects so that the available
    // states change when the country is changed.
    const countryInputs = form.find('[data-country-field="true"]').toArray()
    const stateInputs = form.find('[data-state-field="true"]').toArray()
    for (let index = 0; index < countryInputs.length; index++) {
      const countryInput = countryInputs[index]
      const stateInput = stateInputs[index]
      CountryStateSelect({
        chosen_ui: false,
        country_id: countryInput.id,
        state_id: stateInput.id,
        state_place_holder: ''
      })
    }
  }
})
