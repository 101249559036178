import jQuery from 'jquery'

jQuery(function () {
  // for sign in form
  jQuery('body').on('submit', '#sign_in_form', function () {
    rememberEmail()
  })
  recallEmail()
})

function setCookie (key, value) {
  const expires = new Date()
  // expire in 2 weeks
  expires.setTime(expires.getTime() + (14 * 24 * 60 * 60 * 1000))
  document.cookie = key + '=' + value + ';path=/;expires=' + expires.toUTCString()
}

function getCookie (key) {
  const keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)')
  return keyValue ? keyValue[2] : null
}

function rememberEmail () {
  // remember user's email via cookie so we can recall it after the user signs out
  if (jQuery('#user_remember_me').prop('checked')) {
    setCookie('sentera_user', jQuery('#user_email').val())
  } else {
    setCookie('sentera_user', '')
  }
}

function recallEmail () {
  if (jQuery('#sign_in_form').length === 0) {
    return
  }
  if (jQuery('#sign_in_form #user_email').val() === '') {
    jQuery('#user_email').val(getCookie('sentera_user'))
    if (jQuery('#user_email').val() !== '') {
      // presence of email indicates user asked to be remembered
      jQuery('#user_remember_me').prop('checked', true)
    }
  }
}
